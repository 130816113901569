import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import MoleMapComponent from "./MoleMapComponent";

export default function LocationDialog(props) {
    const { locationDialogOpen, setLocationDialogOpen, locationData, resetZoomToggle, appPhotos } = props;
    return (
        <Dialog
            open={locationDialogOpen}
            maxWidth="100%"
            onClose={() => setLocationDialogOpen(false)}
        >
            <DialogTitle>{locationData.title}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <MoleMapComponent appPhotos={appPhotos} locationData={locationData} resetZoomToggle={resetZoomToggle} widthPercentage={70} showIndividuals={true} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}