import { Box, Button } from '@mui/material';

const BodyBox = (props) => {
    const { boxProps, maxHeight, asset, area, setSelectedArea } = props;
    return (
        <Box {...boxProps}>
            <Button component="span" onClick={() => setSelectedArea(area)}>
                <img src={require(`../assets/${asset}.png`)} style={{ maxHeight }} />
            </Button>
        </Box>
    );
}

export default function ClickableBody(props) {
    const { setSelectedArea } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '80vh', alignItems: "center", mt: 2 }}>
            <BodyBox asset='head' maxHeight='15vh' area="Head" setSelectedArea={setSelectedArea} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <BodyBox boxProps={{ flex: 2, sx: { display: 'flex' } }} asset='right-arm' maxHeight='25vh' area="Right arm" setSelectedArea={setSelectedArea} />
                <BodyBox boxProps={{ flex: 3, sx: { display: 'flex' } }} asset='torso' maxHeight='30vh' area="Torso" setSelectedArea={setSelectedArea} />
                <BodyBox boxProps={{ flex: 2, sx: { display: 'flex' } }} asset='left-arm' maxHeight='25vh' area="Left arm" setSelectedArea={setSelectedArea} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '50%' }}>
                <BodyBox boxProps={{ flex: 1, sx: { display: 'flex', justifyContent: 'center' } }} asset='leg' maxHeight='30vh' area="Right leg" setSelectedArea={setSelectedArea} />
                <BodyBox boxProps={{ flex: 1, sx: { display: 'flex', justifyContent: 'center' } }} asset='leg' maxHeight='30vh' area="Left leg" setSelectedArea={setSelectedArea} />
            </Box>
        </Box>
    );
}