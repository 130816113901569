import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default function ProfileSelectionMenu(props) {
    const { setProfileMenuAnchor, selectedProfile, profileMenuAnchor, appData, handleProfileChange } = props;
    return (
        <>
            <Button
                variant="outlined"
                endIcon={<ArrowDropDownIcon />}
                id="profileMenuButtom"
                onClick={(event) => {
                    setProfileMenuAnchor(event.currentTarget);
                }}
                sx={{ borderRadius: 5 }}
                style={{ textTransform: 'none' }}
            >
                {selectedProfile}
            </Button>
            <Menu
                anchorEl={profileMenuAnchor}
                open={profileMenuAnchor ? true : false}
                onClose={() => setProfileMenuAnchor(null)}
            >
                {appData.map(({ name }) => (
                    <MenuItem key={name} onClick={() => handleProfileChange(name)} selected={name === selectedProfile}>{name}</MenuItem>
                ))}
            </Menu>
        </>
    )
}