export const getPhotoFromSelection = (appPhotos, currentProfile, selection, chosenDate, extension) => {
    if (!chosenDate) {
        return;
    }
    const { area, section, subsection, individual } = selection;
    const pathPart = `${currentProfile}-${area}-${section}${subsection ? `-${subsection}` : ''}${individual ? `-${individual}` : ''}-photos-${chosenDate}`.replace(/[^\d\w\-]/g, '');
    const photoData = appPhotos[`${pathPart}.${extension}`];
    return `data:image/${extension};base64,${photoData}`;
}

// Creating this function as it is useful in a few places throughout the app and saves time rather than repeating similar versions.
export const getAreasSectionsIndividuals = (appData, currentProfile) => {
    const allAreas = (appData || []).find(({ name }) => name === currentProfile)?.areas || [];
    // It's useful to have the section and individual data in the below formats.
    const allSections = allAreas.reduce(
        (result, area) => result.concat(
            area.sections.reduce(
                (res, section) => res.concat([{ area: area.name, section: section.name, photos: section.photos, individuals: section.individuals.map(({ photos, ...others }) => others) }]).concat(
                    section.sections ? section.sections.map(subsection => ({ area: area.name, section: section.name, subsection: subsection.name, photos: subsection.photos, individuals: subsection.individuals.map(({ photos, ...others }) => others) })) : []
                ), [])
        ), []);
    const allIndividuals = allAreas.reduce(
        (result, area) => result.concat(
            area.sections.reduce(
                (res, section) => res.concat(
                    section.individuals.map(({ name, ...others }) => ({ area: area.name, section: section.name, individual: name, ...others }))
                ).concat(
                    section.sections ? section.sections.reduce(
                        (r, subsection) => r.concat(
                            subsection.individuals.map(({ name, ...others }) => ({ area: area.name, section: section.name, subsection: subsection.name, individual: name, ...others }))
                        ), [])
                        : []
                ), [])
        ), []);
    return ({ allAreas: allAreas.map(area => area.name), allSections, allIndividuals });
}