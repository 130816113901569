import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(173, 48, 47)',
      contrastText: 'rgb(255, 255, 255)',
    },
    secondary: {
      main: 'rgb(119, 86, 84)',
      contrastText: 'rgb(255, 255, 255)',
    },
    background: {
      default: 'rgb(255, 251, 255)',
      paper: 'rgb(245, 221, 219)',
    },
    text: {
      primary: 'rgb(32, 26, 25)',
      secondary: 'rgb(83, 67, 66)',
    },
    error: {
      main: 'rgb(186, 26, 26)',
      contrastText: 'rgb(255, 255, 255)',
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(255, 179, 173)',
      contrastText: 'rgb(104, 0, 10)',
    },
    secondary: {
      main: 'rgb(231, 189, 185)',
      contrastText: 'rgb(68, 41, 39)',
    },
    error: {
      main: 'rgb(147, 0, 10)',
      contrastText: 'rgb(255, 180, 171)',
    },
    background: {
      default: 'rgb(32, 26, 25)',
      paper: 'rgb(32, 26, 25)',
    },
    text: {
      primary: 'rgb(237, 224, 222)',
      secondary: 'rgb(237, 224, 222)',
    },
  },
});