import { Box, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch } from "@mui/material";
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import MoleMapComponent from "./MoleMapComponent";

export default function ComparisonDialog(props) {
    const {
        comparisonDialogOpen, setComparisonDialogOpen, comparisonData, showIndividuals,
        setShowIndividuals, showOnePhoto, setShowOnePhoto, resetZoomToggle, setResetZoomToggle,
        largerThanMd, appPhotos
    } = props;
    return (
        <Dialog
            open={comparisonDialogOpen}
            maxWidth="100%"
            onClose={() => setComparisonDialogOpen(false)}
        >
            <DialogTitle>{comparisonData.title}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormGroup row>
                            <FormControlLabel control={<Switch checked={showIndividuals} onChange={() => setShowIndividuals(!showIndividuals)} />} label="Show individuals" />
                            <FormControlLabel control={<Switch checked={showOnePhoto} onChange={() => setShowOnePhoto(!showOnePhoto)} />} label="Show one photo full screen" />
                        </FormGroup>
                        <Button startIcon={<CenterFocusStrongIcon />} variant="contained" onClick={() => setResetZoomToggle(!resetZoomToggle)}>Reset zoom</Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: largerThanMd ? 'row' : 'column', alignItems: 'center' }}>
                        <MoleMapComponent appPhotos={appPhotos} comparisonData={comparisonData} resetZoomToggle={resetZoomToggle} widthPercentage={(showOnePhoto || !largerThanMd) ? 70 : 40} showIndividuals={showIndividuals} />
                        {
                            showOnePhoto ?
                                '' :
                                <MoleMapComponent appPhotos={appPhotos} comparisonData={comparisonData} resetZoomToggle={resetZoomToggle} widthPercentage={(showOnePhoto || !largerThanMd) ? 70 : 40} showIndividuals={showIndividuals} extra />
                        }
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}