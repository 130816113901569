import * as React from 'react';
import { Alert, AppBar, Avatar, Backdrop, Box, Button, CircularProgress, Container, CssBaseline, Grid, IconButton, Input, Snackbar, ThemeProvider, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { darkTheme, lightTheme } from './theme/theme';
import logo from './assets/adaptive-icon.png';
import JSZip from 'jszip';
import ClickableBody from './components/ClickableBody';
import ProfileSelectionMenu from './components/ProfileSelectionMenu';
import { getAreasSectionsIndividuals } from './utils/app-data-manipulation';
import ListOfSectionsIndividuals from './components/ListOfSectionsIndividuals';
import ComparisonDialog from './components/ComparisonDialog';
import LocationDialog from './components/LocationDialog';

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = React.useState(prefersDarkMode);
    const [backdropOpen, setBackDropOpen] = React.useState(false);
    const [sectionsOrIndividuals, setSectionsOrIndividuals] = React.useState("sections");
    const [snackBarState, setSnackBarState] = React.useState({ open: false });
    const [appData, setAppData] = React.useState(null);
    const [appPhotos, setAppPhotos] = React.useState({});
    const [selectedProfile, setSelectedProfile] = React.useState(null);
    const [selectedArea, setSelectedArea] = React.useState("Head");
    const [profileMenuAnchor, setProfileMenuAnchor] = React.useState(null);
    const [comparisonDialogOpen, setComparisonDialogOpen] = React.useState(false);
    const [comparisonData, setComparisonData] = React.useState({});
    const [showOnePhoto, setShowOnePhoto] = React.useState(false);
    const [showIndividuals, setShowIndividuals] = React.useState(false);
    const [resetZoomToggle, setResetZoomToggle] = React.useState(false);
    const [locationDialogOpen, setLocationDialogOpen] = React.useState(false);
    const [locationData, setLocationData] = React.useState({});

    const { allSections, allIndividuals } = getAreasSectionsIndividuals(appData, selectedProfile);

    const theme = React.useMemo(() => darkMode ? darkTheme : lightTheme, [darkMode]);

    const handleSelectFolder = async (event) => {
        setBackDropOpen(true);
        const file = event.target.files[0];
        if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
            const zip = new JSZip();
            await zip.loadAsync(file, { base64: true });
            let newAppData;
            let newAppPhotos = {};
            await Promise.all(Object.keys(zip.files).map(async (relativePath) => {
                const zipEntry = zip.files[relativePath];
                if (relativePath === "appData.json") {
                    const content = await zipEntry.async("text");
                    newAppData = JSON.parse(content);
                } else if (relativePath.match(/^photos\/[\w\d]+/)) {
                    const content = await zipEntry.async("base64");
                    newAppPhotos[relativePath.slice(7)] = content;
                }
            }));
            if (newAppData && Object.keys(newAppPhotos).length > 0) {
                setAppData(newAppData);
                setAppPhotos(newAppPhotos);
                setSelectedProfile(newAppData[0].name);
                setSnackBarState({ open: true, severity: "success", message: "Data successfully loaded from zipped folder!" });
            } else {
                setSnackBarState({ open: true, severity: "warning", message: "There was a problem reading your zipped folder. Make sure it is of the right format generated by the mobile app and make sure that there are some photos to analyse." });
            }
        } else {
            setSnackBarState({ open: true, severity: "error", message: "You must select a zipped folder for analysis in the app." });
        }
        setBackDropOpen(false);
    };

    const handleSectionsIndividualsToggleChange = (event, newValue) => {
        if (newValue) {
            setSectionsOrIndividuals(newValue);
        }
    }

    const handleProfileChange = (name) => {
        setSelectedProfile(name);
        setProfileMenuAnchor(null);
    }

    const largerThanMd = useMediaQuery("(min-width:600px");

    const SelectFolderButton = () => (
        <>
            <Input type="file" inputProps={{ accept: ".zip" }} onChange={handleSelectFolder} style={{ display: 'none' }} id="fileInput" />
            <label htmlFor='fileInput'>
                <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>{appData ? (largerThanMd ? 'Re-select folder' : 'Re-select') : 'Select folder'}</Button>
            </label>
        </>
    )

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth={false} sx={{ paddingTop: '64px' }}>
                <AppBar>
                    <Toolbar sx={{ minHeight: '64px' }}>
                        <Avatar src={logo} sx={{ mr: 2 }} />
                        <Typography variant='h6' sx={{ flexGrow: 1 }}>
                            {largerThanMd ? "Map My Moles" : ""}
                        </Typography>
                        {
                            appData ? <SelectFolderButton /> : ''
                        }
                        <Tooltip title={`Switch to ${darkMode ? 'light' : 'dark'} mode`}>
                            <IconButton sx={{ mr: 2 }} onClick={() => setDarkMode(!darkMode)}>
                                {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip sx={{ mr: 2 }} title="Conveniently view your collected data using this web app by selecting it from your local files and interacting with it here">
                            <InfoIcon />
                        </Tooltip>
                        <Button variant={darkMode ? "outlined" : "contained"} color="secondary" onClick={() => window.close()}>Exit{largerThanMd ? " app" : ""}</Button>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '80vh'
                    }}
                >
                    {
                        appData ?
                            (
                                <Grid container sx={{ margin: 2 }}>
                                    <Grid container item xs={12} md={6} justifyContent="center">
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <ProfileSelectionMenu
                                                setProfileMenuAnchor={setProfileMenuAnchor}
                                                selectedProfile={selectedProfile}
                                                profileMenuAnchor={profileMenuAnchor}
                                                appData={appData}
                                                handleProfileChange={handleProfileChange}
                                            />
                                            <ClickableBody setSelectedArea={setSelectedArea} />
                                        </Box>
                                    </Grid>
                                    <Grid container item xs={12} md={6} justifyContent="center" alignItems="start">
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                            <Typography variant="h5" color="primary" sx={{ mb: 2 }}>{selectedArea}</Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={sectionsOrIndividuals}
                                                exclusive
                                                onChange={handleSectionsIndividualsToggleChange}
                                            >
                                                <ToggleButton value="sections">Body Sections</ToggleButton>
                                                <ToggleButton value="individuals">Individual Moles</ToggleButton>
                                            </ToggleButtonGroup>
                                            {
                                                sectionsOrIndividuals === 'sections' ? '' : (
                                                    allIndividuals.some(s => s.area === selectedArea) ? '' : <Typography variant='body2' sx={{ pt: 2 }}>No individuals in this area, choose another to analyse</Typography>
                                                )
                                            }
                                            <ListOfSectionsIndividuals
                                                sectionsOrIndividuals={sectionsOrIndividuals}
                                                allSections={allSections}
                                                allIndividuals={allIndividuals}
                                                selectedArea={selectedArea}
                                                appPhotos={appPhotos}
                                                selectedProfile={selectedProfile}
                                                setLocationData={setLocationData}
                                                setLocationDialogOpen={setLocationDialogOpen}
                                                setComparisonData={setComparisonData}
                                                setComparisonDialogOpen={setComparisonDialogOpen}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                            : <SelectFolderButton />
                    }
                    <ComparisonDialog
                        comparisonDialogOpen={comparisonDialogOpen}
                        setComparisonDialogOpen={setComparisonDialogOpen}
                        comparisonData={comparisonData}
                        showIndividuals={showIndividuals}
                        setShowIndividuals={setShowIndividuals}
                        showOnePhoto={showOnePhoto}
                        setShowOnePhoto={setShowOnePhoto}
                        resetZoomToggle={resetZoomToggle}
                        setResetZoomToggle={setResetZoomToggle}
                        largerThanMd={largerThanMd}
                        appPhotos={appPhotos}
                    />
                    <LocationDialog
                        locationDialogOpen={locationDialogOpen}
                        setLocationDialogOpen={setLocationDialogOpen}
                        locationData={locationData}
                        resetZoomToggle={resetZoomToggle}
                        appPhotos={appPhotos}
                    />
                    <Backdrop open={backdropOpen}>
                        <CircularProgress sx={{ mr: 2 }} />
                        <Typography>
                            Loading zipped folder...
                        </Typography>
                    </Backdrop>
                    <Snackbar
                        open={snackBarState.open}
                        onClose={() => setSnackBarState({ ...snackBarState, open: false })}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={() => setSnackBarState({ ...snackBarState, open: false })} severity={snackBarState.severity}>
                            {snackBarState.message}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default App;
