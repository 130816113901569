import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import PlaceIcon from '@mui/icons-material/Place';
import { getPhotoFromSelection } from "../utils/app-data-manipulation";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const ZoomablePannableImageWithPins = React.forwardRef((props, ref) => {
    const { widthPercentage, imageData, individuals, showIndividuals } = props;

    const transformWrapperRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
        resetTransform: () => {
            if (transformWrapperRef.current) {
                const { resetTransform } = transformWrapperRef.current;
                resetTransform();
            }
        }
    }));

    return (
        <TransformWrapper ref={transformWrapperRef}>
            <TransformComponent>
                <div style={{ height: `min(${Math.ceil(0.75 * widthPercentage)}vw, 66vh)`, width: `min(${widthPercentage}vw, 88vh)` }}>
                    <img src={imageData} alt={imageData} style={{ height: "100%", width: "100%" }} />
                    {
                        showIndividuals && individuals.map(({ name, pinPhotoPosition }) => (
                            <div key={name} style={{ position: 'absolute', left: `${100 * pinPhotoPosition.x}%`, top: `${100 * pinPhotoPosition.y}%` }}>
                                <div style={{ transform: "translate(-50%, -76%)" }}>
                                    <Tooltip title={name}>
                                        <PlaceIcon color='primary' fontSize="large" sx={{ opacity: 0.7 }} />
                                    </Tooltip>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </TransformComponent>
        </TransformWrapper>
    );
});

export default function MoleMapComponent(props) {
    const { appPhotos, comparisonData, locationData, resetZoomToggle, widthPercentage, showIndividuals, extra } = props;
    const { numPhotos, selectedProfile, area, section, subsection, individual, photos, individuals = [], pinPhotoDate, pinPhotoExtension, pinPhotoPosition } = comparisonData || locationData;
    const [photoIndex, setPhotoIndex] = React.useState(Math.max(numPhotos - (extra ? 2 : 1), 0));
    const zoomablePannableImageWithPinsRef = React.useRef(null);

    const imageData = (
        comparisonData ? getPhotoFromSelection(appPhotos, selectedProfile, { area, section, subsection, individual }, photos[photoIndex].date, photos[photoIndex].extension)
            : getPhotoFromSelection(appPhotos, selectedProfile, { area, section, subsection }, pinPhotoDate, pinPhotoExtension)
    );

    const handleResetTransform = () => {
        if (zoomablePannableImageWithPinsRef.current) {
            zoomablePannableImageWithPinsRef.current.resetTransform();
        }
    }

    // This line means that our function runs whenever the toggle changes value.
    React.useEffect(() => {
        handleResetTransform();
    }, [resetZoomToggle, photoIndex]);

    return (
        <Paper elevation={24} sx={{ padding: 2, margin: 1, pb: 1 }}>
            <ZoomablePannableImageWithPins
                ref={zoomablePannableImageWithPinsRef}
                widthPercentage={widthPercentage}
                imageData={imageData}
                individuals={comparisonData ? individuals.filter(({ pinPhotoDate }) => pinPhotoDate === photos[photoIndex].date) : [{ name: individual, pinPhotoPosition }]}
                showIndividuals={showIndividuals}
            />
            {
                comparisonData ? (
                    <>
                        <Typography style={{ maxHeight: '3em', overflowY: 'scroll', textOverflow: 'ellipsis', whitespace: 'nowrap', maxWidth: `min(${widthPercentage}vw, 88vh)` }}>
                            Notes: {photos[photoIndex].notes}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <IconButton onClick={() => setPhotoIndex(0)}>
                                <FirstPageIcon />
                            </IconButton>
                            <IconButton onClick={() => setPhotoIndex(Math.max(photoIndex - 1, 0))}>
                                <NavigateBeforeIcon />
                            </IconButton>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 1 }}>
                                <Typography>{photos[photoIndex].date}</Typography>
                                <Typography>{`${photoIndex + 1} / ${numPhotos}`}</Typography>
                            </Box>
                            <IconButton onClick={() => setPhotoIndex(Math.min(photoIndex + 1, numPhotos - 1))}>
                                <NavigateNextIcon />
                            </IconButton>
                            <IconButton onClick={() => setPhotoIndex(numPhotos - 1)}>
                                <LastPageIcon />
                            </IconButton>
                        </Box>
                    </>
                ) : ''
            }
        </Paper>
    );
}