import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import ImageIcon from '@mui/icons-material/Image';
import { getPhotoFromSelection } from "../utils/app-data-manipulation";


export default function ListOfSectionsIndividuals(props) {
    const {
        sectionsOrIndividuals, allSections, allIndividuals, selectedArea, appPhotos, selectedProfile,
        setLocationData, setLocationDialogOpen, setComparisonData, setComparisonDialogOpen
    } = props;
    return (
        <List sx={{ width: '90%', maxHeight: '75vh', overflow: 'auto' }}>
            {
                (sectionsOrIndividuals === 'sections' ? allSections : allIndividuals).filter(section => section.area === selectedArea).map(s => {
                    const primaryText = s.individual ? (`${s.individual} (${[s.section, s.subsection].filter(x => x).join(" / ")})`) : (s.subsection || s.section);
                    const numPhotos = s.photos.length;
                    const secondaryText = `${numPhotos} photo${numPhotos === 1 ? '' : 's'}` + (numPhotos > 0 ? `, last date = ${s.photos[numPhotos - 1].date}` : '');
                    const base64ImageData = numPhotos > 0 ? getPhotoFromSelection(appPhotos, selectedProfile, { area: selectedArea, section: s.section, subsection: s.subsection, individual: s.individual }, s.photos[numPhotos - 1].date, s.photos[numPhotos - 1].extension) : undefined;
                    return (
                        <ListItem
                            key={s.individual || primaryText}
                            disablePadding
                            secondaryAction={
                                sectionsOrIndividuals === 'sections' ? undefined :
                                    <IconButton edge="end" onClick={() => {
                                        setLocationData((({ photos, ...others }) => ({ title: [selectedArea, s.section, s.subsection].filter(x => x).join(" / "), selectedProfile, ...others }))(s));
                                        setLocationDialogOpen(true);
                                    }}>
                                        <Tooltip title="See location">
                                            <PlaceIcon color='primary' />
                                        </Tooltip>
                                    </IconButton>
                            }
                        >
                            <ListItemButton
                                onClick={() => {
                                    if (numPhotos > 0) {
                                        setComparisonData({ title: primaryText, numPhotos, selectedProfile, ...s });
                                        setComparisonDialogOpen(true);
                                    }
                                }}
                                sx={{ pl: s.subsection ? 6 : 2 }}
                            >
                                <ListItemText
                                    primary={primaryText}
                                    secondary={secondaryText}
                                />
                                {
                                    numPhotos > 0 ?
                                        <img src={base64ImageData} alt="Alt text" style={{ height: '55px', objectFit: 'contain' }} />
                                        : (
                                            <ListItemIcon>
                                                <ImageIcon />
                                            </ListItemIcon>
                                        )
                                }
                            </ListItemButton>
                        </ListItem>
                    )
                })
            }
        </List>
    )
}